import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import { get, post, remove, put, HttpResponse } from "../../utils/http";
import { ReduxDispatch } from "../../hooks/use-thunk-dispatch";
import { SET_DRIVERS, SET_DRIVER, DriversActionTypes } from "./types";
import { AppState } from "..";
import { Driver, DriverStatus, DriverUpdate } from "../../typings/driver";
import { ActionResponse } from "../orders/types";

interface GetDriversResponse {
  drivers: Driver[];
}

interface GetDriverResponse {
  driver: DriverUpdate;
}

export const setDrivers = (drivers: Driver[]): DriversActionTypes => ({
  type: SET_DRIVERS,
  drivers
});

export const setDriver = (driver: DriverUpdate): DriversActionTypes => ({
  type: SET_DRIVER,
  driver
});

export const getDrivers = (): ThunkAction<
  Promise<unknown>,
  AppState,
  {},
  AnyAction
> => async (dispatch: ReduxDispatch, getState) => {
  try {
    const response = await get<GetDriversResponse>(
      "/customers/customerDrivers",
      getState()
    );
    if (response.parsedBody) {
      const { drivers } = response.parsedBody;
      dispatch(setDrivers(drivers));
    }
  } catch (error) {
    return error;
  }
};

//------------------------------geyByID---------------------------------
export const getDriver = (
  id: string
): ThunkAction<Promise<DriverUpdate | null>, AppState, {}, AnyAction> => async (
  dispatch: ReduxDispatch,
  getState
) => {
  try {
    const response = await get<DriverUpdate>(
      "/customers/customerDrivers/" + id,
      getState()
    );

    if (response.parsedBody) {
      const driver = response.parsedBody;
      dispatch(setDriver(driver));

      return driver;
    }

    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const postDriver = (driver: DriverUpdate) => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
): Promise<ActionResponse> => {
  let response!: HttpResponse<Driver>;

  try {
    response = await post(`/customers/adminCreateDriver`, driver, getState());
    if (response && response.ok && response.parsedBody) {
      return {
        success: true
      };
    } else {
      return {
        success: false,
        error: response.error ? response.error.message : ""
      };
    }
  } catch (e) {
    const error = e as HttpResponse<Driver>;
    return {
      success: false,
      error: error.error ? error.error.message : ""
    };
  }
};

export const putDriver = (driver: DriverUpdate, id: string) => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
): Promise<ActionResponse> => {
  let response!: HttpResponse<Driver>;

  try {
    response = await put(
      `/customers/adminCreateDriver/${id}`,
      driver,
      getState()
    );
    if (response && response.ok && response.parsedBody) {
      return {
        success: true
      };
    } else {
      return {
        success: false,
        error: response.error ? response.error.message : ""
      };
    }
  } catch (e) {
    const error = e as HttpResponse<Driver>;
    return {
      success: false,
      error: error.error ? error.error.message : ""
    };
  }
};

export const changeStatusDriver = (
  driverId: string,
  newStatus: DriverStatus
) => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
): Promise<ActionResponse> => {
  let response!: HttpResponse<Driver>;

  const path =
    newStatus === "ACTIVE"
      ? "/customers/validDriver"
      : "/customers/disabledDriver";

  try {
    response = await put(`${path}/${driverId}`, {}, getState());
    if (response && response.ok && response.parsedBody) {
      return {
        success: true
      };
    } else {
      return {
        success: false,
        error: response.error ? response.error.message : ""
      };
    }
  } catch (e) {
    const error = e as HttpResponse<Driver>;
    return {
      success: false,
      error: error.error ? error.error.message : ""
    };
  }
};
