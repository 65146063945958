import React, { useEffect, useState, useCallback } from "react";
import Layout from "../layout";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppState } from "../../store";
import { Link } from "react-router-dom";
import useDispatch from "../../hooks/use-thunk-dispatch";
import { DriverStatus, DriverUpdate } from "../../typings/driver";
import {
  putDriver,
  postDriver,
  getDriver,
  changeStatusDriver
} from "../../store/drivers/actions";
import { useToast } from "../toast";
import LoadingSpinner from "../spinner/LoadingSpinner";

import {
  Div,
  Input,
  Label,
  ButtonAction,
  ButtonAnnuler,
  DisableButton
} from "./style";
import { confirmAlert } from "react-confirm-alert";

const DriversEdit = () => {
  const [pending, setPending] = React.useState(true);
  const params = useParams();
  const driverId = params["id"];
  const dispatch = useDispatch();
  const { showToast } = useToast();
  const history = useHistory();
  const [inprogress, setInprogress] = React.useState(false);
  const [actionProgress, setActionProgress] = React.useState(false);
  const [changeStateProgress, setChangeStateProgress] = useState(false);

  const [password, setPassword] = useState({
    password: "",
    repeat: ""
  });
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  const driver = useSelector<AppState, DriverUpdate>(
    state => state.drivers.driver
  );
  useEffect(() => {
    if (driverId) {
      setPending(true);
      dispatch(getDriver(driverId));
      setPending(false);
    }
  }, [dispatch]);

  useEffect(() => {
    if (driver) {
      setPending(true);
      setPhoneNumber(driver.phoneNumber);
      setEmail(driver.email);
      setName(driver.name);
      setPending(false);
    }
  }, [driver]);

  const handleChangeStatus = useCallback(
    async (newStatus: DriverStatus) => {
      confirmAlert({
        title: "Confirmer l'opération",
        message: `Etes-vous sûr que vous voulez ${
          newStatus === "ACTIVE" ? "Activer" : "Désactiver"
        } ce coursier`,
        buttons: [
          {
            label: "oui",
            onClick: async () => {
              setChangeStateProgress(true);
              setInprogress(true);
              const response = await dispatch(
                changeStatusDriver(driverId, newStatus)
              );
              if (response.success) {
                newStatus === "ACTIVE"
                  ? showToast("Activation effectuée avec succès")
                  : showToast("Désactivation effectuée avec succès");
                history.push(`/drivers`);
              } else {
                setActionProgress(false);
                setInprogress(false);
                showToast(response.error || "Erreur serveur inconnue");
              }
            }
          },
          {
            label: "non",
            onClick: () => {
              //
            }
          }
        ]
      });
    },
    [driver]
  );

  const renderChangeStatus = useCallback(() => {
    if (driver) {
      const btnMessage = driver.status === "ACTIVE" ? "Désativer" : "Activer";
      const futureStatus: DriverStatus =
        driver.status === "ACTIVE" ? "DISABLED" : "ACTIVE";
      return (
        <DisableButton
          onClick={() => handleChangeStatus(futureStatus)}
          disabled={inprogress}
          style={{
            backgroundColor: futureStatus === "ACTIVE" ? "green" : "red"
          }}
        >
          {changeStateProgress ? "En cours..." : btnMessage}
        </DisableButton>
      );
    } else {
      return null;
    }
  }, [driver]);

  const title = driverId ? "Modifier un coursier" : "Ajouter un coursier";

  const handleSubmit = useCallback(
    async (
      password: { password: string; repeat: string },
      phoneNumber: string,
      email: string,
      name: string
    ) => {
      if (password.repeat !== password.password) {
        return showToast("Les deux mots de passe saisies ne corresponds pas !");
      }

      const driver = {
        password: password.password,
        phoneNumber,
        email,
        name
      } as DriverUpdate;

      if (driverId && !password.password) {
        delete driver.password;
      }

      setActionProgress(true);
      setInprogress(true);
      const response = driverId
        ? await dispatch(putDriver(driver, driverId))
        : await dispatch(postDriver(driver));
      if (response.success) {
        driverId
          ? showToast("Modification effectuée avec succès")
          : showToast("Ajout effectuée avec succès");
        history.push(`/drivers`);
      } else {
        setActionProgress(false);
        setInprogress(false);
        showToast(
          response.error || "Veuillez  vérifier les infomrations entrées"
        );
      }
    },
    [dispatch]
  );

  const renderForm = (
    <Div>
      <Label>Nom</Label>
      <Input value={name} onChange={e => setName(e.target.value)}></Input>

      <Label>Email</Label>
      <Input
        value={email}
        onChange={e => setEmail(e.target.value)}
        type="email"
      ></Input>

      <Label>Numero de téléphone</Label>
      <Input
        value={phoneNumber}
        onChange={e => setPhoneNumber(e.target.value)}
      ></Input>

      <Label>Nouveau mot de passe</Label>
      <Input
        value={password ? password.password : ""}
        onChange={e => setPassword({ ...password, password: e.target.value })}
        type="password"
      ></Input>
      <Label>Resaisir le nouveau mot de passe</Label>
      <Input
        value={password ? password.repeat : ""}
        onChange={e => setPassword({ ...password, repeat: e.target.value })}
        type="password"
      ></Input>

      <ButtonAction
        onClick={() => handleSubmit(password, phoneNumber, email, name)}
        disabled={inprogress}
      >
        {actionProgress ? "En cours..." : title}
      </ButtonAction>
      <Link to="/drivers">
        <ButtonAnnuler disabled={inprogress}>Annuler</ButtonAnnuler>
      </Link>
      {renderChangeStatus()}
    </Div>
  );

  return (
    <Layout title={title} showSearchBag={false}>
      <div className="App">{pending ? <LoadingSpinner /> : renderForm}</div>
    </Layout>
  );
};

export default DriversEdit;
